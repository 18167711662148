.banner{
    width: 100%;
    height: 65vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-color: var(--dark-green); */
    background-attachment: fixed;
}

.child-banner {
    height: 50vh;
}

.banner .overlay{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

#home .banner{
    background-image: url("../images/murchison-national-park-2111157_1920.jpg");
    background-position: top;
}

.destinations-banner {
    background-image: url("../images/african-safari-tour.jpg") !important;
}

.banner .title, 
.banner .subtitle, 
.banner .small-subtitle {
    margin: auto;
    width: 100%;
    padding: 0;
    color: #ffffff !important;
}

.banner .title{
    margin: auto;
    font-size: 1.8rem;
    text-transform: capitalize;
}

.banner .subtitle {
    font-size: 0.9em;
    font-weight: 500;
}

.banner .small-subtitle {
    margin-top: 0.5em;
    font-size: 0.7em;
    font-style: italic;
}

.banner .button {
    margin: 3em 0 0 0;
    width: 7em;
    display: block;
    color: #ffffff;
    text-align: center;
    background-color: var(--hadfar-green);
}

.banner .button:hover {
    color: var(--dark-green);
    background-color: #fff;
}


@media only screen and (min-width: 770px) {
    .banner .title, 
    .banner .subtitle, 
    .banner .small-subtitle {
        width: 70%;
        text-align: center;
    }

    .banner .title{
        font-size: 3em;
    }

    .banner .subtitle {
        margin-top: 0.5rem;
        font-size: 1.3em;
        font-weight: 400;
    }

    .banner .small-subtitle {
        font-size: 1em;
    }

    .banner .button {
        margin: 3em auto 0 auto;
    }

    .child-banner .title {
        font-size: 2rem !important;
    }
}