#home .destinations {
	background-color: #f5f5f5;
}

#home .destinations .wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	gap: 1.5em;
}

#home .destinations .intro .text {
	margin-top: 1rem;
}

.single-destination {
	height: 40vh;
	background-size: cover;
	background-repeat: no-repeat;
}

.single-destination.uganda {
	background-image: url('../images/Kampala-City.jpg');
}

.single-destination.rwanda {
	background-image: url('../images/Kigali-City.jpg');
}

.single-destination * {
	color: #fff;
}

.single-destination .overlay {
	width: 100%;
	height: 100%;
	position: relative;
}

.single-destination .container {
	margin: auto;
	width: 90%;
	height: 100%;
	padding: 1.5em 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.single-destination .title {
	padding: 0;
	text-align: left;
	font-size: var(--small-title-size);
}

.single-destination .tag {
	font-size: 73%;
}

.single-destination .button {
	margin-top: 1em;
	padding: 0;
	font-size: 73%;
	display: inline-block;
}

.single-destination .button:hover {
	text-decoration: underline;
}

.home-page-tours {
	background-color: #fff;
}

.tours-list {
	background-color: #f5f5f5;
}

.package-list p {
	margin-top: 0.5rem;
}

.tours-list .information {
	display: grid;
	gap: 1.5em;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.tours-list .package-list {
	padding: 1.5rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	align-items: center;
	background-color: #fff;
	gap: 1.5rem;
}

.tours-list .package-list .img {
	min-width: 40%;
	height: 13rem;
	background-size: cover;
	background-position: center;
}

.tours-list .package-list .img.one {
	background-image: url('../images/gazelles.jpg');
}

.tours-list .package-list .img.two {
	background-image: url('../images/cultural-tour.jpg');
}

.tours-list .package-list .img.three {
	background-image: url('../images/Kigali-City.jpg');
}

.tours-list .package-list .img.four {
	background-image: url('../images/giraffes-medium.jpg');
}

.tours-list .package-list .small-title {
	margin-top: 0;
}

@media only screen and (min-width: 480px) {

	#home .destinations .wrapper {
		align-items: center;
	}

	#home .destinations .intro .text {
		padding-right: 1.5rem;
		font-size: var(--small-title-size);
	}

	.single-destination {
		border-radius: 0;
	}

	.tours-list .information {
		grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	}
}