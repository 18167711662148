.quote {
	background-color: #fff;
}

.quote .title {
	font-size: var(--small-title-size);
}

form p {
	font-size: var(--small-text-size);
	padding: 0.5em 0;
}

.quote label {
	margin: 0.7em 0 0.1em 0;
	display: block;
	font-size: 94%;
}

.quote input {
	margin-top: 0.5rem;
	width: 100%;
	padding: 0.5em 0.7em;
	display: block;
	border: none;
	background-color: #f1f1f1;
}

.quote button {
	margin: 1.5em auto 0 auto;
	padding: 0.4em 0.7em;
	color: #fff;
	border: 1px solid transparent;
	background-color: var(--hadfar-green);
}

.quote button:hover {
	color: var(--hadfar-green);
	border: 1px solid var(--hadfar-green);
	background-color: #fff;
}

@media only screen and (min-width: 370px) {

	.quote form {
		width: 23%;
	}
	.quote .title {
		font-size: 120%;
	}

	.quote form p {
		font-size: 90%;
	}

	.quote input {
		border: 1px dotted transparent;
	}

	.quote input:focus {
		border: 1px dotted #aaa;
	}
}