header, 
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header {
    width: 100%;
    height: var(--header-height);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
}

header .logo .title {
    font-size: var(--very-small-title-size);
    font-weight: 600;
}

header .logo {
    display: flex;
    align-items: center;
}

header .logo img {
    margin-right: 1rem;
    width: 3rem;
    display: inline-block;
}

header .desc {
    margin-top: 0.5rem;
    font-size: var(--very-small-text-size);
    line-height: 0;
    font-weight: 500;
}

header * {
    color: var(--pws-dark-color);
}

header nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .menu {
    min-width: 12rem;
    display: block;
    position: fixed;
    top: 15vh;
    right: -70%;
}

header .menu a {
    padding: 0.5rem 1rem;
    text-transform: capitalize;
    font-size: var(--small-text-size);
    font-weight: 500;
    display: block;
}

header .menu .active, 
header .menu li a:hover {
    color: var(--hadfar-green);
}

header nav .mobile-menu {
    display: block;
    right: 0%;
    background-color: #fff;
}

header .menu-btn {
    padding: 0.2rem;
    display: inline-block;
    background-color: var(--hadfar-green);
}

header .menu-btn .icon {
    color: #fff;
    font-size: 1.2em;
}

/* nav .special-link {} */

@media only screen and (min-width: 770px) {
    
    header .logo img {
        width: 4rem;
    }

    header .logo .title {
        font-size: var(--small-title-size);
    }

    header .menu {
        min-width: 65%;
        display: flex;
        justify-content: space-between;
        position: relative;
        top: initial;
        right: initial;
    }

    header .menu li {
        margin-left: 2rem;
    }

    header .menu a {
        padding: 0;
        font-size: var(--text-size);
    }

    header .drop-down-menu {
        display: flex;
    }

    header .menu-btn {
        display: none;
    }

}