.contact .icon {
	color: #ffffff;
	margin-right: 5px;
}

.contact {
	background-image: url("../images/murchison-national-park-2111157_1920.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

.contact .overlay {
	padding: 3em 0;
}

.contact .overlay .wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.contact .title {
	font-size: var(--small-title-size);
}

.contact .text {
	margin-top: 0.9rem;
	font-size: var(--text-size)t;
}

.contact .info {
	margin-bottom: 1.5rem;
}

.contact .info * {
	color: #fff;
}

.phone-no-container {
	margin: 0 !important;
	flex: 1;
}

.contact form {
	width: 100%;
	padding: 1.2em;
	background-color: #ffffff;
}

.contact form h2 {
	font-size: 28px;
	text-align: center;
}

.contact label {
	margin: 0.7em 0 0.1em 0;
	/*display: block;*/
	display: none;
	font-size: 94%;
}

.contact input, .contact textarea {
	margin-top: 1em;
	width: 100%;
	padding: 0.5em 0.7em;
	display: block;
	border: 1px dotted transparent;
	background-color: #f1f1f1;
}

.contact input:focus, .contact textarea:focus {
	border: 1px dotted #aaa;
}

.contact textarea {
	font-size: 0.875em;
}


form button {
	margin: 1.5em auto 0 auto;
	padding: 0.4em 1em;
	font-size: 74%;
	color: #fff;
	border: 1px solid transparent;
	background-color: var(--hadfar-green);
}

form button:hover {
	color: var(--hadfar-green);
	border: 1px solid var(--hadfar-green);
	background-color: #fff;
}

@media only screen and (min-width: 768px) {
	
	.contact .overlay .wrapper {
		flex-direction: row;
	}

	.contact .info {
		margin-bottom: 0;
		width: 50%;
	}

	.contact .title {
		font-size: var(--med-title-size);
	}

	.contact form {
		width: 30%;
	}
}