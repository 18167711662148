@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?84865656');
  src: url('../font/fontello.eot?84865656#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?84865656') format('woff2'),
       url('../font/fontello.woff?84865656') format('woff'),
       url('../font/fontello.ttf?84865656') format('truetype'),
       url('../font/fontello.svg?84865656#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?84865656#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-glass-1:before { content: '\edde'; } /* '' */
.icon-music-4:before { content: '\eddf'; } /* '' */
.icon-search-8:before { content: '\ede0'; } /* '' */
.icon-mail-8:before { content: '\ede1'; } /* '' */
.icon-heart-8:before { content: '\ede2'; } /* '' */
.icon-heart-empty-4:before { content: '\ede3'; } /* '' */
.icon-star-8:before { content: '\ede4'; } /* '' */
.icon-star-empty-3:before { content: '\ede5'; } /* '' */
.icon-star-half-1:before { content: '\ede6'; } /* '' */
.icon-user-8:before { content: '\ede7'; } /* '' */
.icon-users-3:before { content: '\ede8'; } /* '' */
.icon-video-5:before { content: '\ede9'; } /* '' */
.icon-videocam-5:before { content: '\edea'; } /* '' */
.icon-picture-5:before { content: '\edeb'; } /* '' */
.icon-camera-7:before { content: '\edec'; } /* '' */
.icon-camera-alt:before { content: '\eded'; } /* '' */
.icon-th-large-3:before { content: '\edee'; } /* '' */
.icon-th-4:before { content: '\edef'; } /* '' */
.icon-th-list-5:before { content: '\edf0'; } /* '' */
.icon-ok-6:before { content: '\edf1'; } /* '' */
.icon-ok-circled-2:before { content: '\edf2'; } /* '' */
.icon-ok-circled2-1:before { content: '\edf3'; } /* '' */
.icon-cancel-7:before { content: '\edf4'; } /* '' */
.icon-cancel-circled-4:before { content: '\edf5'; } /* '' */
.icon-cancel-circled2-1:before { content: '\edf6'; } /* '' */
.icon-plus-6:before { content: '\edf7'; } /* '' */
.icon-plus-circled-2:before { content: '\edf8'; } /* '' */
.icon-minus-4:before { content: '\edf9'; } /* '' */
.icon-minus-circled-2:before { content: '\edfa'; } /* '' */
.icon-help-circled-3:before { content: '\edfb'; } /* '' */
.icon-info-circled-3:before { content: '\edfc'; } /* '' */
.icon-home-6:before { content: '\edfd'; } /* '' */
.icon-link-5:before { content: '\edfe'; } /* '' */
.icon-attach-7:before { content: '\edff'; } /* '' */
.icon-lock-8:before { content: '\ee00'; } /* '' */
.icon-lock-open-7:before { content: '\ee01'; } /* '' */
.icon-pin-2:before { content: '\ee02'; } /* '' */
.icon-eye-7:before { content: '\ee03'; } /* '' */
.icon-eye-off-1:before { content: '\ee04'; } /* '' */
.icon-tag-7:before { content: '\ee05'; } /* '' */
.icon-tags-2:before { content: '\ee06'; } /* '' */
.icon-bookmark-3:before { content: '\ee07'; } /* '' */
.icon-flag-3:before { content: '\ee08'; } /* '' */
.icon-thumbs-up-5:before { content: '\ee09'; } /* '' */
.icon-thumbs-down-4:before { content: '\ee0a'; } /* '' */
.icon-download-6:before { content: '\ee0b'; } /* '' */
.icon-upload-5:before { content: '\ee0c'; } /* '' */
.icon-forward-4:before { content: '\ee0d'; } /* '' */
.icon-export-5:before { content: '\ee0e'; } /* '' */
.icon-pencil-7:before { content: '\ee0f'; } /* '' */
.icon-edit-3:before { content: '\ee10'; } /* '' */
.icon-print-6:before { content: '\ee11'; } /* '' */
.icon-retweet-4:before { content: '\ee12'; } /* '' */
.icon-comment-7:before { content: '\ee13'; } /* '' */
.icon-chat-6:before { content: '\ee14'; } /* '' */
.icon-bell-5:before { content: '\ee15'; } /* '' */
.icon-attention-5:before { content: '\ee16'; } /* '' */
.icon-attention-circled:before { content: '\ee17'; } /* '' */
.icon-location-8:before { content: '\ee18'; } /* '' */
.icon-trash-empty-1:before { content: '\ee19'; } /* '' */
.icon-doc-8:before { content: '\ee1a'; } /* '' */
.icon-folder-6:before { content: '\ee1b'; } /* '' */
.icon-folder-open-2:before { content: '\ee1c'; } /* '' */
.icon-phone-3:before { content: '\ee1d'; } /* '' */
.icon-cog-7:before { content: '\ee1e'; } /* '' */
.icon-cog-alt:before { content: '\ee1f'; } /* '' */
.icon-wrench-4:before { content: '\ee20'; } /* '' */
.icon-basket-4:before { content: '\ee21'; } /* '' */
.icon-calendar-8:before { content: '\ee22'; } /* '' */
.icon-login-3:before { content: '\ee23'; } /* '' */
.icon-logout-3:before { content: '\ee24'; } /* '' */
.icon-volume-off-4:before { content: '\ee25'; } /* '' */
.icon-volume-down-2:before { content: '\ee26'; } /* '' */
.icon-volume-up-3:before { content: '\ee27'; } /* '' */
.icon-headphones-3:before { content: '\ee28'; } /* '' */
.icon-clock-8:before { content: '\ee29'; } /* '' */
.icon-block-5:before { content: '\ee2a'; } /* '' */
.icon-resize-full-6:before { content: '\ee2b'; } /* '' */
.icon-resize-small-4:before { content: '\ee2c'; } /* '' */
.icon-resize-vertical-2:before { content: '\ee2d'; } /* '' */
.icon-resize-horizontal-2:before { content: '\ee2e'; } /* '' */
.icon-move-3:before { content: '\ee2f'; } /* '' */
.icon-zoom-in-4:before { content: '\ee30'; } /* '' */
.icon-zoom-out-4:before { content: '\ee31'; } /* '' */
.icon-down-circled2:before { content: '\ee32'; } /* '' */
.icon-up-circled2:before { content: '\ee33'; } /* '' */
.icon-down-dir-3:before { content: '\ee34'; } /* '' */
.icon-up-dir-2:before { content: '\ee35'; } /* '' */
.icon-left-dir-2:before { content: '\ee36'; } /* '' */
.icon-right-dir-3:before { content: '\ee37'; } /* '' */
.icon-down-open-3:before { content: '\ee38'; } /* '' */
.icon-left-open-5:before { content: '\ee39'; } /* '' */
.icon-right-open-5:before { content: '\ee3a'; } /* '' */
.icon-up-open-3:before { content: '\ee3b'; } /* '' */
.icon-down-big:before { content: '\ee3c'; } /* '' */
.icon-left-big:before { content: '\ee3d'; } /* '' */
.icon-right-big:before { content: '\ee3e'; } /* '' */
.icon-up-big:before { content: '\ee3f'; } /* '' */
.icon-right-hand-1:before { content: '\ee40'; } /* '' */
.icon-left-hand-1:before { content: '\ee41'; } /* '' */
.icon-up-hand-1:before { content: '\ee42'; } /* '' */
.icon-down-hand-1:before { content: '\ee43'; } /* '' */
.icon-cw-5:before { content: '\ee44'; } /* '' */
.icon-ccw-2:before { content: '\ee45'; } /* '' */
.icon-arrows-cw-3:before { content: '\ee46'; } /* '' */
.icon-shuffle-4:before { content: '\ee47'; } /* '' */
.icon-play-5:before { content: '\ee48'; } /* '' */
.icon-play-circled2-1:before { content: '\ee49'; } /* '' */
.icon-stop-6:before { content: '\ee4a'; } /* '' */
.icon-pause-5:before { content: '\ee4b'; } /* '' */
.icon-to-end-3:before { content: '\ee4c'; } /* '' */
.icon-to-end-alt:before { content: '\ee4d'; } /* '' */
.icon-to-start-3:before { content: '\ee4e'; } /* '' */
.icon-to-start-alt:before { content: '\ee4f'; } /* '' */
.icon-fast-fw-1:before { content: '\ee50'; } /* '' */
.icon-fast-bw:before { content: '\ee51'; } /* '' */
.icon-eject-3:before { content: '\ee52'; } /* '' */
.icon-target-5:before { content: '\ee53'; } /* '' */
.icon-signal-5:before { content: '\ee54'; } /* '' */
.icon-award-2:before { content: '\ee55'; } /* '' */
.icon-inbox-4:before { content: '\ee56'; } /* '' */
.icon-globe-6:before { content: '\ee57'; } /* '' */
.icon-cloud-8:before { content: '\ee58'; } /* '' */
.icon-flash-4:before { content: '\ee59'; } /* '' */
.icon-umbrella-1:before { content: '\ee5a'; } /* '' */
.icon-flight-2:before { content: '\ee5b'; } /* '' */
.icon-leaf-3:before { content: '\ee5c'; } /* '' */
.icon-font-2:before { content: '\ee5d'; } /* '' */
.icon-bold-1:before { content: '\ee5e'; } /* '' */
.icon-italic-1:before { content: '\ee5f'; } /* '' */
.icon-text-height-1:before { content: '\ee60'; } /* '' */
.icon-text-width-1:before { content: '\ee61'; } /* '' */
.icon-align-left-1:before { content: '\ee62'; } /* '' */
.icon-align-center-1:before { content: '\ee63'; } /* '' */
.icon-align-right-1:before { content: '\ee64'; } /* '' */
.icon-align-justify-1:before { content: '\ee65'; } /* '' */
.icon-list-4:before { content: '\ee66'; } /* '' */
.icon-indent-left-2:before { content: '\ee67'; } /* '' */
.icon-indent-right-2:before { content: '\ee68'; } /* '' */
.icon-scissors-1:before { content: '\ee69'; } /* '' */
.icon-briefcase-3:before { content: '\ee6a'; } /* '' */
.icon-off-1:before { content: '\ee6b'; } /* '' */
.icon-road-1:before { content: '\ee6c'; } /* '' */
.icon-list-alt:before { content: '\ee6d'; } /* '' */
.icon-qrcode-1:before { content: '\ee6e'; } /* '' */
.icon-barcode-1:before { content: '\ee6f'; } /* '' */
.icon-book-4:before { content: '\ee70'; } /* '' */
.icon-adjust-2:before { content: '\ee71'; } /* '' */
.icon-tint-1:before { content: '\ee72'; } /* '' */
.icon-check-3:before { content: '\ee73'; } /* '' */
.icon-asterisk-1:before { content: '\ee74'; } /* '' */
.icon-gift-2:before { content: '\ee75'; } /* '' */
.icon-fire-3:before { content: '\ee76'; } /* '' */
.icon-magnet-2:before { content: '\ee77'; } /* '' */
.icon-chart-bar-5:before { content: '\ee78'; } /* '' */
.icon-credit-card-4:before { content: '\ee79'; } /* '' */
.icon-floppy-1:before { content: '\ee7a'; } /* '' */
.icon-megaphone-3:before { content: '\ee7b'; } /* '' */
.icon-key-5:before { content: '\ee7c'; } /* '' */
.icon-truck-1:before { content: '\ee7d'; } /* '' */
.icon-hammer:before { content: '\ee7e'; } /* '' */
.icon-facebook-squared-1:before { content: '\ee7f'; } /* '' */
.icon-github-squared:before { content: '\ee80'; } /* '' */
.icon-linkedin-squared:before { content: '\ee81'; } /* '' */
.icon-twitter-squared:before { content: '\ee82'; } /* '' */
.icon-lemon:before { content: '\ee83'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-check-empty-1:before { content: '\f096'; } /* '' */
.icon-bookmark-empty-1:before { content: '\f097'; } /* '' */
.icon-phone-squared:before { content: '\f098'; } /* '' */
.icon-twitter-7:before { content: '\f099'; } /* '' */
.icon-facebook-7:before { content: '\f09a'; } /* '' */
.icon-github-circled-4:before { content: '\f09b'; } /* '' */
.icon-rss-7:before { content: '\f09e'; } /* '' */
.icon-hdd-2:before { content: '\f0a0'; } /* '' */
.icon-certificate-2:before { content: '\f0a3'; } /* '' */
.icon-left-circled-2:before { content: '\f0a8'; } /* '' */
.icon-right-circled-2:before { content: '\f0a9'; } /* '' */
.icon-up-circled-2:before { content: '\f0aa'; } /* '' */
.icon-down-circled-2:before { content: '\f0ab'; } /* '' */
.icon-tasks-1:before { content: '\f0ae'; } /* '' */
.icon-filter-1:before { content: '\f0b0'; } /* '' */
.icon-resize-full-alt-2:before { content: '\f0b2'; } /* '' */
.icon-beaker-1:before { content: '\f0c3'; } /* '' */
.icon-docs-1:before { content: '\f0c5'; } /* '' */
.icon-blank:before { content: '\f0c8'; } /* '' */
.icon-menu-3:before { content: '\f0c9'; } /* '' */
.icon-list-bullet:before { content: '\f0ca'; } /* '' */
.icon-list-numbered-1:before { content: '\f0cb'; } /* '' */
.icon-strike:before { content: '\f0cc'; } /* '' */
.icon-underline:before { content: '\f0cd'; } /* '' */
.icon-table:before { content: '\f0ce'; } /* '' */
.icon-magic:before { content: '\f0d0'; } /* '' */
.icon-pinterest-circled-2:before { content: '\f0d2'; } /* '' */
.icon-pinterest-squared:before { content: '\f0d3'; } /* '' */
.icon-gplus-squared:before { content: '\f0d4'; } /* '' */
.icon-gplus-3:before { content: '\f0d5'; } /* '' */
.icon-money-2:before { content: '\f0d6'; } /* '' */
.icon-columns:before { content: '\f0db'; } /* '' */
.icon-sort:before { content: '\f0dc'; } /* '' */
.icon-sort-down:before { content: '\f0dd'; } /* '' */
.icon-sort-up:before { content: '\f0de'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin-6:before { content: '\f0e1'; } /* '' */
.icon-gauge-2:before { content: '\f0e4'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-sitemap:before { content: '\f0e8'; } /* '' */
.icon-paste:before { content: '\f0ea'; } /* '' */
.icon-lightbulb-3:before { content: '\f0eb'; } /* '' */
.icon-exchange-1:before { content: '\f0ec'; } /* '' */
.icon-download-cloud-2:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud-4:before { content: '\f0ee'; } /* '' */
.icon-user-md:before { content: '\f0f0'; } /* '' */
.icon-stethoscope:before { content: '\f0f1'; } /* '' */
.icon-suitcase-1:before { content: '\f0f2'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-coffee-1:before { content: '\f0f4'; } /* '' */
.icon-food-1:before { content: '\f0f5'; } /* '' */
.icon-doc-text-2:before { content: '\f0f6'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-hospital-1:before { content: '\f0f8'; } /* '' */
.icon-ambulance:before { content: '\f0f9'; } /* '' */
.icon-medkit:before { content: '\f0fa'; } /* '' */
.icon-fighter-jet:before { content: '\f0fb'; } /* '' */
.icon-beer-2:before { content: '\f0fc'; } /* '' */
.icon-h-sigh:before { content: '\f0fd'; } /* '' */
.icon-plus-squared-1:before { content: '\f0fe'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-desktop-3:before { content: '\f108'; } /* '' */
.icon-laptop-2:before { content: '\f109'; } /* '' */
.icon-tablet-3:before { content: '\f10a'; } /* '' */
.icon-mobile-6:before { content: '\f10b'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-quote-left-1:before { content: '\f10d'; } /* '' */
.icon-quote-right-1:before { content: '\f10e'; } /* '' */
.icon-spinner:before { content: '\f110'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-reply-4:before { content: '\f112'; } /* '' */
.icon-github-6:before { content: '\f113'; } /* '' */
.icon-folder-empty-2:before { content: '\f114'; } /* '' */
.icon-folder-open-empty-1:before { content: '\f115'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-gamepad:before { content: '\f11b'; } /* '' */
.icon-keyboard-1:before { content: '\f11c'; } /* '' */
.icon-flag-empty:before { content: '\f11d'; } /* '' */
.icon-flag-checkered:before { content: '\f11e'; } /* '' */
.icon-terminal-1:before { content: '\f120'; } /* '' */
.icon-code-3:before { content: '\f121'; } /* '' */
.icon-reply-all-2:before { content: '\f122'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-direction-2:before { content: '\f124'; } /* '' */
.icon-crop:before { content: '\f125'; } /* '' */
.icon-fork:before { content: '\f126'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-help-3:before { content: '\f128'; } /* '' */
.icon-info-3:before { content: '\f129'; } /* '' */
.icon-attention-alt-1:before { content: '\f12a'; } /* '' */
.icon-superscript:before { content: '\f12b'; } /* '' */
.icon-subscript:before { content: '\f12c'; } /* '' */
.icon-eraser:before { content: '\f12d'; } /* '' */
.icon-puzzle-1:before { content: '\f12e'; } /* '' */
.icon-mic-5:before { content: '\f130'; } /* '' */
.icon-mute-1:before { content: '\f131'; } /* '' */
.icon-shield:before { content: '\f132'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-extinguisher:before { content: '\f134'; } /* '' */
.icon-rocket-1:before { content: '\f135'; } /* '' */
.icon-maxcdn:before { content: '\f136'; } /* '' */
.icon-angle-circled-left:before { content: '\f137'; } /* '' */
.icon-angle-circled-right:before { content: '\f138'; } /* '' */
.icon-angle-circled-up:before { content: '\f139'; } /* '' */
.icon-angle-circled-down:before { content: '\f13a'; } /* '' */
.icon-html5-1:before { content: '\f13b'; } /* '' */
.icon-css3:before { content: '\f13c'; } /* '' */
.icon-anchor-2:before { content: '\f13d'; } /* '' */
.icon-lock-open-alt-2:before { content: '\f13e'; } /* '' */
.icon-bullseye:before { content: '\f140'; } /* '' */
.icon-ellipsis:before { content: '\f141'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-rss-squared:before { content: '\f143'; } /* '' */
.icon-play-circled-1:before { content: '\f144'; } /* '' */
.icon-ticket-2:before { content: '\f145'; } /* '' */
.icon-minus-squared-1:before { content: '\f146'; } /* '' */
.icon-minus-squared-alt:before { content: '\f147'; } /* '' */
.icon-level-up-1:before { content: '\f148'; } /* '' */
.icon-level-down-1:before { content: '\f149'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-pencil-squared:before { content: '\f14b'; } /* '' */
.icon-link-ext-alt:before { content: '\f14c'; } /* '' */
.icon-export-alt:before { content: '\f14d'; } /* '' */
.icon-compass-5:before { content: '\f14e'; } /* '' */
.icon-expand:before { content: '\f150'; } /* '' */
.icon-collapse:before { content: '\f151'; } /* '' */
.icon-expand-right:before { content: '\f152'; } /* '' */
.icon-euro:before { content: '\f153'; } /* '' */
.icon-pound:before { content: '\f154'; } /* '' */
.icon-dollar-1:before { content: '\f155'; } /* '' */
.icon-rupee:before { content: '\f156'; } /* '' */
.icon-yen:before { content: '\f157'; } /* '' */
.icon-rouble:before { content: '\f158'; } /* '' */
.icon-won:before { content: '\f159'; } /* '' */
.icon-bitcoin-1:before { content: '\f15a'; } /* '' */
.icon-doc-inv-1:before { content: '\f15b'; } /* '' */
.icon-doc-text-inv-1:before { content: '\f15c'; } /* '' */
.icon-sort-name-up:before { content: '\f15d'; } /* '' */
.icon-sort-name-down:before { content: '\f15e'; } /* '' */
.icon-sort-alt-up:before { content: '\f160'; } /* '' */
.icon-sort-alt-down:before { content: '\f161'; } /* '' */
.icon-sort-number-up:before { content: '\f162'; } /* '' */
.icon-sort-number-down:before { content: '\f163'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-youtube-squared:before { content: '\f166'; } /* '' */
.icon-youtube-4:before { content: '\f167'; } /* '' */
.icon-xing-1:before { content: '\f168'; } /* '' */
.icon-xing-squared:before { content: '\f169'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-dropbox-2:before { content: '\f16b'; } /* '' */
.icon-stackoverflow-2:before { content: '\f16c'; } /* '' */
.icon-instagram-5:before { content: '\f16d'; } /* '' */
.icon-flickr-4:before { content: '\f16e'; } /* '' */
.icon-adn:before { content: '\f170'; } /* '' */
.icon-bitbucket-1:before { content: '\f171'; } /* '' */
.icon-bitbucket-squared:before { content: '\f172'; } /* '' */
.icon-tumblr-4:before { content: '\f173'; } /* '' */
.icon-tumblr-squared:before { content: '\f174'; } /* '' */
.icon-down-6:before { content: '\f175'; } /* '' */
.icon-up-6:before { content: '\f176'; } /* '' */
.icon-left-5:before { content: '\f177'; } /* '' */
.icon-right-5:before { content: '\f178'; } /* '' */
.icon-apple:before { content: '\f179'; } /* '' */
.icon-windows-1:before { content: '\f17a'; } /* '' */
.icon-android-1:before { content: '\f17b'; } /* '' */
.icon-linux:before { content: '\f17c'; } /* '' */
.icon-dribbble-5:before { content: '\f17d'; } /* '' */
.icon-skype-6:before { content: '\f17e'; } /* '' */
.icon-foursquare-2:before { content: '\f180'; } /* '' */
.icon-trello:before { content: '\f181'; } /* '' */
.icon-female-2:before { content: '\f182'; } /* '' */
.icon-male-2:before { content: '\f183'; } /* '' */
.icon-gittip:before { content: '\f184'; } /* '' */
.icon-sun-3:before { content: '\f185'; } /* '' */
.icon-moon-4:before { content: '\f186'; } /* '' */
.icon-box-4:before { content: '\f187'; } /* '' */
.icon-bug:before { content: '\f188'; } /* '' */
.icon-vkontakte-2:before { content: '\f189'; } /* '' */
.icon-weibo-1:before { content: '\f18a'; } /* '' */
.icon-renren-1:before { content: '\f18b'; } /* '' */
.icon-pagelines:before { content: '\f18c'; } /* '' */
.icon-stackexchange:before { content: '\f18d'; } /* '' */
.icon-right-circled2:before { content: '\f18e'; } /* '' */
.icon-left-circled2:before { content: '\f190'; } /* '' */
.icon-collapse-left:before { content: '\f191'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-wheelchair:before { content: '\f193'; } /* '' */
.icon-vimeo-squared:before { content: '\f194'; } /* '' */
.icon-try:before { content: '\f195'; } /* '' */
.icon-plus-squared-alt:before { content: '\f196'; } /* '' */
.icon-space-shuttle:before { content: '\f197'; } /* '' */
.icon-slack:before { content: '\f198'; } /* '' */
.icon-mail-squared:before { content: '\f199'; } /* '' */
.icon-wordpress-3:before { content: '\f19a'; } /* '' */
.icon-openid-1:before { content: '\f19b'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-graduation-cap-2:before { content: '\f19d'; } /* '' */
.icon-yahoo-1:before { content: '\f19e'; } /* '' */
.icon-google-1:before { content: '\f1a0'; } /* '' */
.icon-reddit-2:before { content: '\f1a1'; } /* '' */
.icon-reddit-squared:before { content: '\f1a2'; } /* '' */
.icon-stumbleupon-circled-1:before { content: '\f1a3'; } /* '' */
.icon-stumbleupon-3:before { content: '\f1a4'; } /* '' */
.icon-delicious-2:before { content: '\f1a5'; } /* '' */
.icon-digg-2:before { content: '\f1a6'; } /* '' */
.icon-pied-piper-squared:before { content: '\f1a7'; } /* '' */
.icon-pied-piper-alt:before { content: '\f1a8'; } /* '' */
.icon-drupal-1:before { content: '\f1a9'; } /* '' */
.icon-joomla:before { content: '\f1aa'; } /* '' */
.icon-language-1:before { content: '\f1ab'; } /* '' */
.icon-fax:before { content: '\f1ac'; } /* '' */
.icon-building-filled:before { content: '\f1ad'; } /* '' */
.icon-child-1:before { content: '\f1ae'; } /* '' */
.icon-paw:before { content: '\f1b0'; } /* '' */
.icon-spoon:before { content: '\f1b1'; } /* '' */
.icon-cube:before { content: '\f1b2'; } /* '' */
.icon-cubes:before { content: '\f1b3'; } /* '' */
.icon-behance-2:before { content: '\f1b4'; } /* '' */
.icon-behance-squared:before { content: '\f1b5'; } /* '' */
.icon-steam-1:before { content: '\f1b6'; } /* '' */
.icon-steam-squared:before { content: '\f1b7'; } /* '' */
.icon-recycle:before { content: '\f1b8'; } /* '' */
.icon-cab:before { content: '\f1b9'; } /* '' */
.icon-taxi:before { content: '\f1ba'; } /* '' */
.icon-tree-3:before { content: '\f1bb'; } /* '' */
.icon-spotify-2:before { content: '\f1bc'; } /* '' */
.icon-deviantart-2:before { content: '\f1bd'; } /* '' */
.icon-soundcloud-3:before { content: '\f1be'; } /* '' */
.icon-database-3:before { content: '\f1c0'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-file-archive:before { content: '\f1c6'; } /* '' */
.icon-file-audio:before { content: '\f1c7'; } /* '' */
.icon-file-video:before { content: '\f1c8'; } /* '' */
.icon-file-code:before { content: '\f1c9'; } /* '' */
.icon-vine:before { content: '\f1ca'; } /* '' */
.icon-codeopen:before { content: '\f1cb'; } /* '' */
.icon-jsfiddle:before { content: '\f1cc'; } /* '' */
.icon-lifebuoy-2:before { content: '\f1cd'; } /* '' */
.icon-circle-notch:before { content: '\f1ce'; } /* '' */
.icon-rebel:before { content: '\f1d0'; } /* '' */
.icon-empire:before { content: '\f1d1'; } /* '' */
.icon-git-squared:before { content: '\f1d2'; } /* '' */
.icon-git:before { content: '\f1d3'; } /* '' */
.icon-hacker-news:before { content: '\f1d4'; } /* '' */
.icon-tencent-weibo:before { content: '\f1d5'; } /* '' */
.icon-qq-1:before { content: '\f1d6'; } /* '' */
.icon-wechat:before { content: '\f1d7'; } /* '' */
.icon-paper-plane-3:before { content: '\f1d8'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-circle-thin:before { content: '\f1db'; } /* '' */
.icon-header:before { content: '\f1dc'; } /* '' */
.icon-paragraph:before { content: '\f1dd'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-share-3:before { content: '\f1e0'; } /* '' */
.icon-share-squared:before { content: '\f1e1'; } /* '' */
.icon-bomb:before { content: '\f1e2'; } /* '' */
.icon-soccer-ball:before { content: '\f1e3'; } /* '' */
.icon-tty:before { content: '\f1e4'; } /* '' */
.icon-binoculars:before { content: '\f1e5'; } /* '' */
.icon-plug-1:before { content: '\f1e6'; } /* '' */
.icon-slideshare-1:before { content: '\f1e7'; } /* '' */
.icon-twitch:before { content: '\f1e8'; } /* '' */
.icon-yelp-1:before { content: '\f1e9'; } /* '' */
.icon-newspaper-2:before { content: '\f1ea'; } /* '' */
.icon-wifi-1:before { content: '\f1eb'; } /* '' */
.icon-calc:before { content: '\f1ec'; } /* '' */
.icon-paypal-2:before { content: '\f1ed'; } /* '' */
.icon-gwallet:before { content: '\f1ee'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-discover:before { content: '\f1f2'; } /* '' */
.icon-cc-amex:before { content: '\f1f3'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-cc-stripe:before { content: '\f1f5'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-bell-off-empty:before { content: '\f1f7'; } /* '' */
.icon-trash-8:before { content: '\f1f8'; } /* '' */
.icon-copyright:before { content: '\f1f9'; } /* '' */
.icon-at-4:before { content: '\f1fa'; } /* '' */
.icon-eyedropper-1:before { content: '\f1fb'; } /* '' */
.icon-brush-3:before { content: '\f1fc'; } /* '' */
.icon-birthday:before { content: '\f1fd'; } /* '' */
.icon-chart-area-1:before { content: '\f1fe'; } /* '' */
.icon-chart-pie-4:before { content: '\f200'; } /* '' */
.icon-chart-line-1:before { content: '\f201'; } /* '' */
.icon-lastfm-4:before { content: '\f202'; } /* '' */
.icon-lastfm-squared:before { content: '\f203'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-bicycle-1:before { content: '\f206'; } /* '' */
.icon-bus-1:before { content: '\f207'; } /* '' */
.icon-ioxhost:before { content: '\f208'; } /* '' */
.icon-angellist-1:before { content: '\f209'; } /* '' */
.icon-cc-3:before { content: '\f20a'; } /* '' */
.icon-shekel:before { content: '\f20b'; } /* '' */
.icon-meanpath:before { content: '\f20c'; } /* '' */
.icon-buysellads:before { content: '\f20d'; } /* '' */
.icon-connectdevelop:before { content: '\f20e'; } /* '' */
.icon-dashcube:before { content: '\f210'; } /* '' */
.icon-forumbee:before { content: '\f211'; } /* '' */
.icon-leanpub:before { content: '\f212'; } /* '' */
.icon-sellsy:before { content: '\f213'; } /* '' */
.icon-shirtsinbulk:before { content: '\f214'; } /* '' */
.icon-simplybuilt:before { content: '\f215'; } /* '' */
.icon-skyatlas:before { content: '\f216'; } /* '' */
.icon-cart-plus:before { content: '\f217'; } /* '' */
.icon-cart-arrow-down:before { content: '\f218'; } /* '' */
.icon-diamond-1:before { content: '\f219'; } /* '' */
.icon-ship:before { content: '\f21a'; } /* '' */
.icon-user-secret:before { content: '\f21b'; } /* '' */
.icon-motorcycle:before { content: '\f21c'; } /* '' */
.icon-street-view:before { content: '\f21d'; } /* '' */
.icon-heartbeat:before { content: '\f21e'; } /* '' */
.icon-venus:before { content: '\f221'; } /* '' */
.icon-mars:before { content: '\f222'; } /* '' */
.icon-mercury:before { content: '\f223'; } /* '' */
.icon-transgender:before { content: '\f224'; } /* '' */
.icon-transgender-alt:before { content: '\f225'; } /* '' */
.icon-venus-double:before { content: '\f226'; } /* '' */
.icon-mars-double:before { content: '\f227'; } /* '' */
.icon-venus-mars:before { content: '\f228'; } /* '' */
.icon-mars-stroke:before { content: '\f229'; } /* '' */
.icon-mars-stroke-v:before { content: '\f22a'; } /* '' */
.icon-mars-stroke-h:before { content: '\f22b'; } /* '' */
.icon-neuter:before { content: '\f22c'; } /* '' */
.icon-genderless:before { content: '\f22d'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-pinterest-4:before { content: '\f231'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-server:before { content: '\f233'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-bed:before { content: '\f236'; } /* '' */
.icon-viacoin:before { content: '\f237'; } /* '' */
.icon-train:before { content: '\f238'; } /* '' */
.icon-subway:before { content: '\f239'; } /* '' */
.icon-medium:before { content: '\f23a'; } /* '' */
.icon-y-combinator:before { content: '\f23b'; } /* '' */
.icon-optin-monster:before { content: '\f23c'; } /* '' */
.icon-opencart:before { content: '\f23d'; } /* '' */
.icon-expeditedssl:before { content: '\f23e'; } /* '' */
.icon-battery-4:before { content: '\f240'; } /* '' */
.icon-battery-3:before { content: '\f241'; } /* '' */
.icon-battery-2:before { content: '\f242'; } /* '' */
.icon-battery-1:before { content: '\f243'; } /* '' */
.icon-battery-0:before { content: '\f244'; } /* '' */
.icon-mouse-pointer:before { content: '\f245'; } /* '' */
.icon-i-cursor:before { content: '\f246'; } /* '' */
.icon-object-group:before { content: '\f247'; } /* '' */
.icon-object-ungroup:before { content: '\f248'; } /* '' */
.icon-sticky-note:before { content: '\f249'; } /* '' */
.icon-sticky-note-o:before { content: '\f24a'; } /* '' */
.icon-cc-jcb:before { content: '\f24b'; } /* '' */
.icon-cc-diners-club:before { content: '\f24c'; } /* '' */
.icon-clone:before { content: '\f24d'; } /* '' */
.icon-balance-scale:before { content: '\f24e'; } /* '' */
.icon-hourglass-o:before { content: '\f250'; } /* '' */
.icon-hourglass-2:before { content: '\f251'; } /* '' */
.icon-hourglass-3:before { content: '\f252'; } /* '' */
.icon-hourglass-4:before { content: '\f253'; } /* '' */
.icon-hourglass-5:before { content: '\f254'; } /* '' */
.icon-hand-grab-o:before { content: '\f255'; } /* '' */
.icon-hand-paper-o:before { content: '\f256'; } /* '' */
.icon-hand-scissors-o:before { content: '\f257'; } /* '' */
.icon-hand-lizard-o:before { content: '\f258'; } /* '' */
.icon-hand-spock-o:before { content: '\f259'; } /* '' */
.icon-hand-pointer-o:before { content: '\f25a'; } /* '' */
.icon-hand-peace-o:before { content: '\f25b'; } /* '' */
.icon-trademark:before { content: '\f25c'; } /* '' */
.icon-registered:before { content: '\f25d'; } /* '' */
.icon-creative-commons:before { content: '\f25e'; } /* '' */
.icon-gg:before { content: '\f260'; } /* '' */
.icon-gg-circle:before { content: '\f261'; } /* '' */
.icon-tripadvisor:before { content: '\f262'; } /* '' */
.icon-odnoklassniki-1:before { content: '\f263'; } /* '' */
.icon-odnoklassniki-square:before { content: '\f264'; } /* '' */
.icon-get-pocket:before { content: '\f265'; } /* '' */
.icon-wikipedia-w:before { content: '\f266'; } /* '' */
.icon-safari:before { content: '\f267'; } /* '' */
.icon-chrome-2:before { content: '\f268'; } /* '' */
.icon-firefox-1:before { content: '\f269'; } /* '' */
.icon-opera-1:before { content: '\f26a'; } /* '' */
.icon-internet-explorer:before { content: '\f26b'; } /* '' */
.icon-television:before { content: '\f26c'; } /* '' */
.icon-contao:before { content: '\f26d'; } /* '' */
.icon-500px:before { content: '\f26e'; } /* '' */
.icon-amazon-1:before { content: '\f270'; } /* '' */
.icon-calendar-plus-o:before { content: '\f271'; } /* '' */
.icon-calendar-minus-o:before { content: '\f272'; } /* '' */
.icon-calendar-times-o:before { content: '\f273'; } /* '' */
.icon-calendar-check-o:before { content: '\f274'; } /* '' */
.icon-industry:before { content: '\f275'; } /* '' */
.icon-map-pin:before { content: '\f276'; } /* '' */
.icon-map-signs:before { content: '\f277'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-map-2:before { content: '\f279'; } /* '' */
.icon-commenting:before { content: '\f27a'; } /* '' */
.icon-commenting-o:before { content: '\f27b'; } /* '' */
.icon-houzz-1:before { content: '\f27c'; } /* '' */
.icon-vimeo-5:before { content: '\f27d'; } /* '' */
.icon-black-tie:before { content: '\f27e'; } /* '' */
.icon-fonticons:before { content: '\f280'; } /* '' */
.icon-reddit-alien:before { content: '\f281'; } /* '' */
.icon-edge:before { content: '\f282'; } /* '' */
.icon-credit-card-alt:before { content: '\f283'; } /* '' */
.icon-codiepie:before { content: '\f284'; } /* '' */
.icon-modx:before { content: '\f285'; } /* '' */
.icon-fort-awesome:before { content: '\f286'; } /* '' */
.icon-usb:before { content: '\f287'; } /* '' */
.icon-product-hunt:before { content: '\f288'; } /* '' */
.icon-mixcloud:before { content: '\f289'; } /* '' */
.icon-scribd-1:before { content: '\f28a'; } /* '' */
.icon-pause-circle:before { content: '\f28b'; } /* '' */
.icon-pause-circle-o:before { content: '\f28c'; } /* '' */
.icon-stop-circle:before { content: '\f28d'; } /* '' */
.icon-stop-circle-o:before { content: '\f28e'; } /* '' */
.icon-shopping-bag:before { content: '\f290'; } /* '' */
.icon-shopping-basket:before { content: '\f291'; } /* '' */
.icon-hashtag:before { content: '\f292'; } /* '' */
.icon-bluetooth:before { content: '\f293'; } /* '' */
.icon-bluetooth-b:before { content: '\f294'; } /* '' */
.icon-percent:before { content: '\f295'; } /* '' */
.icon-gitlab:before { content: '\f296'; } /* '' */
.icon-wpbeginner:before { content: '\f297'; } /* '' */
.icon-wpforms:before { content: '\f298'; } /* '' */
.icon-envira:before { content: '\f299'; } /* '' */
.icon-universal-access-1:before { content: '\f29a'; } /* '' */
.icon-wheelchair-alt:before { content: '\f29b'; } /* '' */
.icon-question-circle-o:before { content: '\f29c'; } /* '' */
.icon-blind-1:before { content: '\f29d'; } /* '' */
.icon-audio-description:before { content: '\f29e'; } /* '' */
.icon-volume-control-phone:before { content: '\f2a0'; } /* '' */
.icon-braille-1:before { content: '\f2a1'; } /* '' */
.icon-assistive-listening-systems:before { content: '\f2a2'; } /* '' */
.icon-american-sign-language-interpreting:before { content: '\f2a3'; } /* '' */
.icon-asl-interpreting:before { content: '\f2a4'; } /* '' */
.icon-glide:before { content: '\f2a5'; } /* '' */
.icon-glide-g:before { content: '\f2a6'; } /* '' */
.icon-sign-language:before { content: '\f2a7'; } /* '' */
.icon-low-vision:before { content: '\f2a8'; } /* '' */
.icon-viadeo-1:before { content: '\f2a9'; } /* '' */
.icon-viadeo-square:before { content: '\f2aa'; } /* '' */
.icon-snapchat:before { content: '\f2ab'; } /* '' */
.icon-snapchat-ghost:before { content: '\f2ac'; } /* '' */
.icon-snapchat-square:before { content: '\f2ad'; } /* '' */
.icon-pied-piper:before { content: '\f2ae'; } /* '' */
.icon-first-order:before { content: '\f2b0'; } /* '' */
.icon-yoast:before { content: '\f2b1'; } /* '' */
.icon-themeisle:before { content: '\f2b2'; } /* '' */
.icon-google-plus-circle:before { content: '\f2b3'; } /* '' */
.icon-font-awesome:before { content: '\f2b4'; } /* '' */
.icon-handshake-o:before { content: '\f2b5'; } /* '' */
.icon-envelope-open:before { content: '\f2b6'; } /* '' */
.icon-envelope-open-o:before { content: '\f2b7'; } /* '' */
.icon-linode:before { content: '\f2b8'; } /* '' */
.icon-address-book-1:before { content: '\f2b9'; } /* '' */
.icon-address-book-o:before { content: '\f2ba'; } /* '' */
.icon-address-card:before { content: '\f2bb'; } /* '' */
.icon-address-card-o:before { content: '\f2bc'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */
.icon-id-badge:before { content: '\f2c1'; } /* '' */
.icon-id-card:before { content: '\f2c2'; } /* '' */
.icon-id-card-o:before { content: '\f2c3'; } /* '' */
.icon-quora-1:before { content: '\f2c4'; } /* '' */
.icon-free-code-camp:before { content: '\f2c5'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
.icon-thermometer-1:before { content: '\f2c7'; } /* '' */
.icon-thermometer-3:before { content: '\f2c8'; } /* '' */
.icon-thermometer-2:before { content: '\f2c9'; } /* '' */
.icon-thermometer-quarter:before { content: '\f2ca'; } /* '' */
.icon-thermometer-0:before { content: '\f2cb'; } /* '' */
.icon-shower:before { content: '\f2cc'; } /* '' */
.icon-bath:before { content: '\f2cd'; } /* '' */
.icon-podcast-1:before { content: '\f2ce'; } /* '' */
.icon-window-maximize:before { content: '\f2d0'; } /* '' */
.icon-window-minimize:before { content: '\f2d1'; } /* '' */
.icon-window-restore:before { content: '\f2d2'; } /* '' */
.icon-window-close:before { content: '\f2d3'; } /* '' */
.icon-window-close-o:before { content: '\f2d4'; } /* '' */
.icon-bandcamp-1:before { content: '\f2d5'; } /* '' */
.icon-grav:before { content: '\f2d6'; } /* '' */
.icon-etsy:before { content: '\f2d7'; } /* '' */
.icon-imdb:before { content: '\f2d8'; } /* '' */
.icon-ravelry:before { content: '\f2d9'; } /* '' */
.icon-eercast:before { content: '\f2da'; } /* '' */
.icon-microchip:before { content: '\f2db'; } /* '' */
.icon-snowflake-o:before { content: '\f2dc'; } /* '' */
.icon-superpowers:before { content: '\f2dd'; } /* '' */
.icon-wpexplorer:before { content: '\f2de'; } /* '' */
.icon-meetup-1:before { content: '\f2e0'; } /* '' */