* {
    margin: 0;
    padding: 0;
    font-family: Poppins, Verdana, Roboto, sans-serif;
    box-sizing: border-box;
    outline: none;
    /* color: #000 !important; */
    transition: all 0.3s ease-in-out;
}

body {
    font-size: 100%;
    background-color: #fff;
}

a {
    text-decoration: none;
    color: var(--text-color);
}

a:hover {
    color: var(--pws-primary-color);
}

.list .title {
    font-size: var(--small-title-size);
}

li {
    list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    color: var(--title-color);
}

h1, h2 {
	font-size: 2.25em;
}

h3, h4 {
	font-size: 1.3em;
}

h5, h6 {
	font-size: 1.125em;
}

p, li, .text, label {
    font-size: var(--text-size);
    color: var(--text-color);
}

p, .text {
    line-height: 1.8rem;
}

img {
    width: 100%;
    display: block;
    object-fit: cover;
}

button, .button {
    padding: 0.4em 0.7em;
    text-align: center;
    border: none;
    cursor: pointer;
    border-radius: var(--std-bd-rad);
}

textarea, input {
	resize: none;
	outline: none;
}

.section {
    padding: 4em 0;
}

.wrapper {
    margin: auto;
    width: 85%;
}

.card {
    overflow: hidden;
    border-radius: var(--med-bd-rad);
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.main-title {
    font-size: var(--main-title-size);
}

.medium-title {
    font-size: var(--med-title-size);
}

.small-title {
    font-size: var(--small-title-size);
}

.toi, .special-link {
    color: var(--pws-primary-color) !important;
}

@media only screen and (min-width: 480px) {

    .wrapper {
        width: 90%;
    }

}