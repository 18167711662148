#tour-packages .banner{
    background-image: url("../images/giraffes-medium.jpg");
	background-position: bottom;
}

.tours {
	height: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	gap: 1.5em;
}

#tour-packages .card * {
	color: #575757;
}

#tour-packages .card {
	width: 100%;
	background-color: #fff;
}

.tour-link {
	background-color: transparent;
}

.tour-link .title {
	font-size: var(--small-title-size);
	font-weight: 600;
	text-align: left;
}

.tour-link .text {
	font-size: var(--small-text-size);
}

.tour-link a:hover {
	color: var(--hadfar-green);
}

.intro-tip {
	margin-top: 2.5em;
	background-color: black;
}

.intro-tip .title {
	font-size: 0.8em;
	color: #717171;
}

.tour-holder {
	margin: 3em auto;
	width: 90%;
}

.tour {
	margin: auto;
	height: 0;
	padding: 0 1.5em;
	position: relative;
	overflow: hidden;
	overflow: hidden;
	transition: height 0.2s ease-out;
	background-color: #ffffff;
}

.tour h3, 
.tour h4, 
.highlights-info h3, 
.highlights-info h4 {
	padding: 16px 0;
	text-align: left;
}

.tour h3{
	font-size: 24px;
	/*background-color: #ffffff;*/
}

.tour .title {
	width: 100%;
	background-color: #ffffff;
}

.highlights-info h3 {
	font-size: 20px;
}

.tour h4 {
	font-size: 18px;
}

.tour p, 
.highlights-info p {
	padding: 4px 0;
}

.tour:hover .accordion {
	top: 0;
}

@media only screen and (min-width: 370px) {

	.tours {
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	}
}