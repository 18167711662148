.first-page-title {
	padding-top: 3em;
}

.section-intro {
	margin: 0 auto 2rem auto;
}

.section-intro .title {
	text-align: left;
	font-size: var(--med-title-size);
}

.section-intro .text {
	text-align: left;
	line-height: 1.7em;
}

.information {
	display: grid;
}

.auto-grid-4 {
	display: grid !important;
	grid-template-columns: repeat(auto-fit, minmax(15.625em, 1fr));
}

.read-more-btn {
    width: 5em;
    margin-top: 0.75em;
    padding: 0.375em !important;
    /*background-color: #00695c;
    background-color: #184848;*/
    color: #ffffff !important;
    background-color: #4CAF50;
    text-align: center;
    display: block;
}

.more-btn{
	margin: 2em auto 0 auto;
	width: 9em;
	padding: 0.4em 0.5em;
	text-align: center;
	font-size: 0.9em;
	display: block;
	border: 1px solid var(--hadfar-green);
	color: #ffffff;
	background-color: var(--hadfar-green);
}

.more-btn:hover {
	color: var(--hadfar-green);
	background-color: #ffffff;
	border: 1px solid var(--hadfar-green);
}


@media only screen and (min-width: 370px) {

	.section-intro {
		width: 55%;
	}

	.section-intro .title {
		text-align: center;
	}
	
	.section-intro .text {
		text-align: center;
		font-size: var(--small-title-size);
	}
}