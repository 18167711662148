:root {

    --main-title-size: 3rem;
    --med-title-size: 2rem;
    --small-title-size: 1.2rem;
    --title-color: #000;

    --text-size: 1rem;
    --small-text-size: 0.85rem;
    --very-small-text-size: 0.75rem;
    --text-color: #222;

    --std-bd-rad: 0.2rem;
    --med-bd-rad: 0.3rem;

    --header-height: 15vh;
    --footer-height: 10vh;

    --page-size-padding: 4rem;


    --dark-green: #087c42;

    --teal-green-color: #00cc99;

    /* --hadfar-green: #00cc66; */
    --hadfar-green: #60B746;
	--hadfar-orange: #ff9900;
	--hadfar-text-color: #222;
	--hadfar-title-color: #000;
}

/*
	Here are a number of options on green colours, in order of arrival (first is latest).
	-------------------------------------------------------------------------------------
	#00cc66 - rgb(0, 204, 102)
	#00cc99
	#075e54
	#128c7e

	Here are a number of options on orange colours, in order of arrival (first is latest).
	-------------------------------------------------------------------------------------
	#ff9900 - rgb(255, 153, 0)
*/