footer {
    padding: 1.5rem 0;
    width: 100%;
    min-height: var(--footer-height);
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .spaced {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

footer .social-links {
    display: flex;
    align-items: center;
}

footer .link-icons {
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

footer .link-icons li {
    padding: 0.3rem;
}

footer .text {
    font-size: var(--small-text-size);
    text-align: left;
}

footer .text a {
    color: var(--hadfar-green);
}

@media only screen and (min-width: 480px) {

    footer {
        padding: 0;
    }

    footer .text {
        text-align: center;
    }

}