#about .banner {
	background-image: url('../images/african-safari-tour.jpg');
	background-position: bottom;
}

.about-info {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.about-item {
	width: 100%;
	background-color: #ffffff;
}

.about-item h3, 
.about-item p {
	text-align: left;
}

.about-item h3 {
	padding: 6px 0;
}

.about-item p {
	padding: 8px 0;
	font-weight: 400;
}

.about-know {
	background-color: #fff;
}

.about-know .packages {
	margin-top: 4rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
	gap: 1.5em;
}

.about-know .package-list { 
	background-color: transparent;
}

@media only screen and (min-width: 480px) {
	.about-info {
		gap: 3em;
	}

	.about-info .left {
		width: 35%;
	}
	
	.about-info .right {
		width: 60%;
	}

	.about-know .packages {
		grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	}
}