#blog .banner {
    background-image: url('../images/sleeping_lion.jpg');
}

.posts-list {
    background-color: #fff;
}

.posts-list .title {
    font-size: var(--small-title-size);
}

.posts-list .post-preview {
    margin-bottom: 1.5rem;
}