.page {
    margin-top: var(--header-height);
    width: 100%;
    min-height: 75vh;
}

.child-page {
    padding: var(--page-size-padding) 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.side-menu {
    margin-top: 1.5rem;
    width: 100%;
}

.side-menu .list {
    margin: 1rem 0;
}

.side-menu .list li {
    padding: 0.2rem 0;
}

.side-menu li ,
.side-menu p {
    font-size: var(--small-text-size);
}

.status-page {
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-page .content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-page .content button {
    margin-top: 1.5rem;
    font-weight: 500;
    color: #fff;
    background-color: var(--hadfar-green);
}

@media only screen and (min-width: 770px) {

    /* .page {
        padding: 3rem 0;
    } */

    .side-menu {
        margin-top: 0;
        width: 25%;
    }


    .side-page {
        width: 70%;
    }

}