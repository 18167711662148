@import url('./quotes.css');

.banner-area {
    width: 100%;
    height: 45vh;
    background-size: cover;
    background-position: center;
    position: relative;
    background-attachment: fixed;
}

.banner-area * {
    color: #fff;
}

.banner-area .info {
    padding: 0 0 2rem 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.banner-area .days {
    margin: 0.5rem 0;
}

.banner-area .title {
    font-size: var(--med-title-size);
}

.banner-area .ldng {
    min-width: 12%;
    display: inline-block;
}

.banner-area .text {
    font-size: var(--text-size);
}

#rw3d-gr-tr .banner-area {
    background-image: url("../images/rwandan-gorilla.jpg");
    background-position: top;
}

#ug8d-gl-chimp .banner-area {
    background-image: url("../images/chimps-small.jpg");
}

#rw3d-gr-ex .banner-area {
    background-image: url("../images/gorilla.jpg");
    background-position: top;
}

#ug6d-bw-qn .banner-area {
    background-image: url("../images/zebra-small.jpg");
}

#ug10d-tour .banner-area {
	background-image: url("../images/buffalo.jpg");
	background-position: bottom;
}

#bt-sun .banner-area {
	background-image: url("../images/impala-male-0030.jpg");
	background-position: top;
}

#sec-of-ug-rw .banner-area {
	background-image: url("../images/shoebill-stork-small.jpg");
	background-position: top;
}

#mcsh_fall_saf .banner-area {
	background-image: url("../images/murchison_falls.jpg");
    background-position: top;
}

#tst-of-ug .banner-area {
	/* background-image: url("../images/crested-cranes-small.jpg"); */
	background-image: url("../images/sleeping_lion.jpg");
    background-position: bottom;
}

#s17d-int .banner-area {
	background-image: url("../images/canopy-walk-nyungwe-small.jpg");
    background-position: bottom;
}

.day-nav {
    width: 100%;
    height: 10vh;
    background-color: #fff;
}

.day-list {
    height: 100%;
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.day-list li {
    margin-right: 1rem;
    padding: 0.2rem 0.3rem;
    text-transform: capitalize;
    display: block;
    font-size: var(--small-text-size);
    cursor: pointer;
}

.day-list li:hover {
    color: var(--hadfar-green);
    font-weight: 500;
}
.day-list li.active {
    color: #fff;
    background-color: var(--hadfar-green);
    border-radius: var(--std-bd-rad);
}

.package-detail {
    background-color: #fff;
}

.tabcontent {
    display: none;
}

.package-detail .title {
    font-size: 1.5rem;
}

.package-detail .text {
    margin-top: 0.7rem;
}

.package-detail li {
    padding: 0.3rem 0;
    list-style-type: decimal;
    list-style-position: inside;
}

.package-detail .details p {
    font-size: 0.95rem;
}

.package-detail .small-title {
    margin: 1rem 0 0.5rem 0;
}

@media only screen and (min-width: 770px) {

    .banner-area .ldng {
        min-width: 8%;
    }

    .package-detail {
        width: 70%;
        background-color: #fff;
    }
}