#destinations .banner {
    background-image: url("../images/murchison-national-park-2111157_1920.jpg");
}

#destinations .child-page:nth-child(3) {
	padding-top: 0;
}

.country-image {
	margin: 1.5rem 0;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
	gap: 1rem;
}

.country-image .c-div {
	height: 12em;
	flex: 1;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 0.2em;
}
	
.country-image .c-div:nth-child(1) {
	grid-column: 1/3;
}

.country-image .c-div .info {
	margin: auto;
	width: 100%;
	padding: 0.6em 0.4em;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.7), black);
}

.country-image .c-div .info .title, 
.country-image .c-div .info .text {
	color: #ffffff;
}

.country-image .c-div .info .title {
	font-size: var(--text-size);
	text-align: left;
	font-weight: 600;
}

#uganda .country-image .c-div .info .text {
	font-size: var(--small-text-size);
}

#uganda .country-image .c-div:nth-child(1) {
	background-image: url("../images/Kampala-City.jpg");
	background-position: bottom;
}

#uganda .country-image .c-div:nth-child(2) {
	background-image: url("../images/crested_crane-small.jpg");
	background-position: right;
}

#uganda .country-image .c-div:nth-child(3) {
	background-image: url("../images/shoebill-stork-small.jpg");
	background-position: top;
}


/*Rwanda Images start here*/

#rwanda .country-image .c-div:nth-child(1) {
	background-image: url("../images/Kigali-City.jpg");
	background-position: center;
}

#rwanda .country-image .c-div:nth-child(2) {
	background-image: url("../images/Rwanda-Hills.jpg");
	background-position: center;
}

#rwanda .country-image .c-div:nth-child(3) {
	background-image: url("../images/rwandan-gorilla.jpg");
	background-position: center;
}

/* .country {
	background-color: transparent;
} */

/* .country .overview {
}

.country .facts {
} */

.country .title {
	padding: 0.5em 0 0 0;
	text-align: left;
}
.country .subtitle {
	padding: 0;
	font-size: 0.9em;
}

.overview-title {
	margin-bottom: 0.2em;
}

.country .text {
	padding: 0.4em 0;
}

.details-div .title {
	margin-bottom: 0.5rem;
}

.details-div {
	margin-bottom: 1.5rem;
}

.home-page-destinations .destination-link-container {
	margin: auto;
	width: 90%;
}

.home-page-tours {
	position: relative;
}

.tour-container {
	display: grid;
	gap: 1.5em;
	grid-template-columns: repeat(auto-fit, minmax(9em, 1fr));
}

.home-page-tours .single-tour {
	min-width: 10em;
	height: 8em;
	transition: 0.4s;
	background-size: 135%;
	/* background-size: cover; */
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 0.25em;
	overflow: hidden;
}

.home-page-tours .single-tour:hover{
	background-size: 140%;
}

.home-page-tours .single-tour .overlay {
	width: 100%;
	height: 100%;
	position: relative;
	opacity: 1;
	transition: 0.4s;
}

.home-page-tours .single-tour .overlay p {
	padding: 2rem;
	position: absolute;
	left: 0;
	bottom: 0;
	color: #ffffff;
	font-weight: 400;
}

.home-page-tours .single-tour .title {
	width: 100%;
	height: 220px;
	position: relative;
}

.home-page-tours .single-tour .title h3 {
	padding: 16px;
	font-size: 16px;
	text-align: left;
	position: absolute;
	bottom: 0;
	color: #ffffff;
}

.home-page-tours .single-tour .text {
	padding: 24px 16px;
	font-size: 13.5px;
}

.tour-container #kibale {
    background-image: url("../images/chimps-small.jpg");
}

.tour-container #queen {
    background-image: url("../images/zebra-small.jpg");
}

/* .tour-container #kazinga {
    background-image: url("../images/hippos.jpg");
} */

.tour-container #bwindi {
    background-image: url("../images/gorilla.jpg");
}

.tour-container #mburo {
    background-image: url("../images/impala-male-0030.jpg");
}

.tour-container #volcanoes {
    background-image: url("../images/rwandan-gorilla.jpg");
}

.tour-container #murchison {
    background-image: url("../images/buffalo.jpg");
}

.tour-container #kigali {
    background-image: url("../images/Kigali-City.jpg");
}




.img-show-child .overlay:hover,
.img-show-child .overlay:hover a {
    opacity: 1;
}

.img-show-child .overlay:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 380px) {

	.country-image .c-div {
		/* min-width: 10em; */
		min-height: 15em;
	}

	.country-image .c-div:nth-child(2) {
		/* margin: 1rem 0 0 1rem; */
		margin: 0;
	}
}

@media only screen and (min-width: 770px) {

	#destinations .side-menu {
		width: 20%;
	}
	
	#destinations .side-page {
		width: 75%;
	}

	.home-page-tours .tour-container {
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		/* justify-content: space-between; */
	}

	.home-page-tours .single-tour {
		height: 18rem;
		border-radius: 0;
		overflow: auto;

	}

	.home-page-tours .single-tour .overlay {
		opacity: 1;
	}

	.home-page-tours .single-tour .overlay:hover {
		opacity: 0;
	}

	.country-image {
		grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
		gap: 1.5rem;
	}

	.country-image .c-div {
		height: 20rem;
	}
	
	.country-image .c-div:nth-child(1) {
		grid-column: auto;
	}

	.country-image .c-div .info {
		padding: 0.5em 1em;
	}
}